import Head from "@modules/common/components/head"
import Layout from "@modules/layout/templates"
import { NextPageWithLayout } from "types/global"
import { GetStaticProps } from "next"
import { getSinglePost, post } from "@lib/data/posts"
import RenderItem from "@modules/common/render-item"

type PageProps = {
    post?: post
}
const Index: NextPageWithLayout<PageProps> = ({ post }) => {
    return (
        <>
            <Head
                title={post?.seo?.metaTitle ?? post?.title ?? ""}
                description={post?.seo?.metaDescription}
                canonical={post?.seo?.canonicalURL}
                robots={post?.seo?.metaRobots ?? "index, follow"}
                image={post?.seo?.metaImage?.url ?? null}
            />
            <div className={"grain-background"}>
                <div
                    className={
                        "relative z-10 grid grid-cols-6 px-0 pt-0 pb-7 lg:pb-20"
                    }
                >
                    <div
                        className={
                            "absolute z-20 bg-red-600 text-white p-3 rounded-full text-[0.8rem] small:text-[19px] right-4 top-4 small:right-10 small:top-[25vh] text-center w-[130px] md:w-[200px] aspect-video small:aspect-square flex items-center justify-center"
                        }
                    >
                        GRATIS VERZENDING <br />
                        AKUPANELEN
                        <br />
                        NL &amp; BE
                    </div>
                    {post?.content &&
                        post?.content.map((item, index) => (
                            <RenderItem
                                key={`render-${index}`}
                                pos={index}
                                item={item}
                            />
                        ))}
                </div>
                <div
                    className={"absolute inset-0 z-0 pointer-events-none"}
                    style={{
                        background:
                            "linear-gradient(\n" +
                            "    to right,\n" +
                            "    transparent 0%,\n" +
                            "    transparent 33.33%,\n" +
                            "    #f6f6f6 33.33%,\n" +
                            "    #f6f6f6 100%\n" +
                            "  )"
                    }}
                >
                    &nbsp;
                </div>
            </div>
        </>
    )
}

Index.getLayout = (page) => <Layout>{page}</Layout>

export const getStaticProps: GetStaticProps = async (context) => {
    const post = await getSinglePost("home", "pages")
    //console.log(post)
    return {
        props: {
            post: post
        },
        revalidate: 30
    }
}
export default Index
